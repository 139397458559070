import React from "react";
import { translate } from "react-i18next";
import { observer } from 'mobx-react';
import ReceiptFormView from '../View';
import SettingsStore from "../../../../../Stores/SettingsStore";
import userStore from "../../../../../Stores/UserStore";
import APIProvider from "../../../../../Stores/APIProvider/APIProvider";

@translate(['parking'], { wait: true })
@observer
class ReceiptFormContainer extends React.Component {
  constructor(props) {
    super(props);
    if (undefined === this.props.isGetReceipt) {
      this.state = {
        isGetReceipt: false,
        receiptnumber: 0,
      };
    } else {
      this.state = {
        isGetReceipt: true,
        receiptnumber: this.props.receiptnumber,
      };
    }
  }

  onAbort = () => {
    const { onAbort } = this.props;
    this.props.setReceiptMode({amount: 0, isReceiptMode: false});
    onAbort();
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let result;
    if (formData.get('type') == '1') {
      result = await APIProvider.createReceiptIndividual({
        'firstName': formData.get('firstName'),
        'lastName': formData.get('lastName'),
        'middleName': formData.get('middleName'),
        'address': formData.get('address'),
        'typeDoc': formData.get('typeDoc'),
        'seriesDoc': formData.get('seriesDoc'),
        'numberDoc': formData.get('numberDoc'),
        'DateDoc': formData.get('DateDoc'),
        'issuer': formData.get('issuer'),
        'type': formData.get('type'),
        'sum': formData.get('sum'),
      });
    } else if (formData.get('type') == '2') {
      result = await APIProvider.createReceiptLegal({
      'name': formData.get('name'),
      'ogrn': formData.get('ogrn'),
      'inn': formData.get('inn'),
      'kpp': formData.get('kpp'),
      'address': formData.get('address'),
      'type': formData.get('type'),
      'sum': formData.get('sum'),
    });
    } else {
      result = null;
    }

    this.setState({
      receiptnumber: result.result.receiptnumber,
      isGetReceipt: true,
    })
  }

  render() {
    return (
      <ReceiptFormView
        t={this.props.t}
        services={SettingsStore.settings.paymentsMethods}
        phones={userStore.getAllPhones()}
        onAbort={this.onAbort}
        amount={this.props.amount}
        onSubmit={this.handleFormSubmit}
        receiptnumber={this.state.receiptnumber}
        isGetReceipt={this.state.isGetReceipt}
        receiptType={this.props.receiptType}
      />
    );
  }
}

export default ReceiptFormContainer;