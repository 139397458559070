import { observable, action, computed, toJS } from "mobx";
import APIProvider from "./APIProvider";
import isArray from "lodash/isArray";

class ReceiptStore {
  @observable receipts = [];

  @computed
  get receiptsList() {
    let receipts = toJS(this.receipts);
    receipts = isArray(receipts) ? receipts : receipts.result;
    return receipts;
  }

  @computed
  get hasReceipts() {
    // console.log('!!toJS(this.receipts).length', toJS(this.receipts));

    return !!this.receiptsList.length;
    // console.log('this.receipts', receipts);
    // return [];
  }

  @action
  getReceiptsList = async () => {
    this.receipts = await APIProvider.getReceiptsList();
  };
}

export default new ReceiptStore();
