import { computed, observable } from 'mobx';
import UserStore from '../UserStore';
import CoopUser from '../CoopUser';
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import CityStore from '../CityStore';
import Token from '../../utils/Token';

class APIProvider {
  @observable loading = false;

  @computed
  get token() {
    return Token.getTokenForCity(CityStore.zone, UserStore.USER_TYPE);
  }

  get coopToken() {
    return Token.getTokenForCity(CityStore.zone, CoopUser.USER_TYPE);
  }

  /**
   *
   * @description Send API request
   * @param method? : string [GET or POST]
   * @param path? : string
   * @param body? : Object
   * @param params? : Object
   * @return {Promise.<*>}
   * @constructor
   */
  async APIRequest({ method, path, body, headers, params, responseType }) {
    const options = {
      method: 'get',
      path: '/',
      body: {},
      params: {},
      ...{ method, body, path, params, responseType },
    };

    const serverURI = CityStore.serverURI;

    try {
      const paramsPart =
        !!options.params && Object.keys(options.params).length > 0
          ? '?' + qs.stringify(options.params)
          : '';
      this.loading = true;
      const response = await axios({
        method: String(options.method).toUpperCase(),
        url: serverURI + options.path + paramsPart,
        data: qs.stringify(options.body),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...headers,
        },
        responseType: options.responseType,
      });
      this.loading = false;
      if (response && response.status === 200) {
        try {
          let data = JSON.parse(response.data);
          UserStore.checkToken(data);
          return data;
        } catch (e) {
          let data = response.data;
          UserStore.checkToken(data);
          return data;
        }
      }

      return false;
    } catch (e) {
      this.loading = false;
      console.warn('API request error', e);

      return false;
    }
  }

  /**
   * @description Login user with email and password
   * @param login : string
   * @param password : string
   * @return {Promise.<Object>}
   */
  async login(login, password) {
    return await this.APIRequest({
      method: 'POST',
      path: '/api/auth/login',
      body: { login, password },
    });
  }

  /**
   * @description Login user with email and password
   * @param login : string
   * @param password : string
   * @return {Promise.<Object>}
   */
  async loginV2(login, password) {
    return await this.APIRequest({
      method: 'POST',
      path: '/api/auth/login-v2',
      body: { login, password },
    });
  }

  /**
   * @description Login user with email and password
   * @param login : string
   * @param password : string
   * @param captcha : string
   * @return {Promise.<Object>}
   */
  async loginV3(login, password, captcha = '') {
    captcha = captcha === '' || captcha === null ? null : captcha;
    return await this.APIRequest({
      method: 'POST',
      path: '/api/auth/login-v3',
      body: { login, password, captcha },
    });
  }

  /**
   * @description Login user with email and password
   * @param login : string
   * @param password : string
   * @param captcha : string
   * @return {Promise.<Object>}
   */
  async loginV4(login, password, captcha = '') {
    captcha = captcha === '' || captcha === null ? null : captcha;
    return await this.APIRequest({
      method: 'POST',
      path: '/api/auth/login-v4',
      body: { login, password, captcha },
    });
  }

  /**
   * @description Login user with email|phone and password
   * @param login : string
   * @param password : string
   * @param captcha : string
   * @return {Promise.<Object>}
   */
  async loginV5(login, password, captcha = '') {
    captcha = captcha === '' || captcha === null ? null : captcha;
    return await this.APIRequest({
      method: 'POST',
      path: '/api/auth/login-v5',
      body: { login, password, captcha },
    });
  }

  /**
   *
   * @description Register user with field and returns token.
   * @param field : Object [email, pass, phone, firstname, lastname,
   *   middlename?]
   * @return {Promise.<string|boolean>}
   */
  async registration(field) {
    if (field.phone) {
      field.phone = String(field.phone).replace(/[^\d+]/g, '');
    }

    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/registration',
      body: field,
    });

    return result;
  }

  /**
   *
   * @description Register user with field and returns token.
   * @param field : Object [email, pass, phone, firstname, lastname,
   *   middlename?]
   * @return {Promise.<string|boolean>}
   */
  async registrationV2(field) {
    if (field.phone) {
      field.phone = String(field.phone).replace(/[^\d+]/g, '');
    }

    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/registration-v2',
      body: field,
    });

    if (typeof result === 'object' && result.hasOwnProperty('token')) {
      return result.token;
    } else {
      return result;
    }
  }

  async changePhoneInRegistration(phone, token) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/change-phone',
      body: {
        phone,
        token,
      },
    });

    return response;
  }

  /**
   *
   * @description Check email for existing.
   * @param email : string
   * @return {Promise.<boolean>}
   */
  async checkEmail(email) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/check-email',
      body: { email },
    });

    return result ? result : null;
  }

  /**
   * @description Check phone number for existing.
   * @param phoneNumber : string
   * @return {Promise.<boolean>}
   */
  async checkPhone(phoneNumber) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/check-phone',
      body: { phone: phoneNumber },
    });

    return result ? result : null;
  }

  /**
   *
   * @description Get map info.
   * @return {Promise.<Object>}
   */
  async getMapInfo() {
    return await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-map-info-v2',
    });
  }

  /**
   *
   * @description Get map info with images.
   * @return {Promise.<Object>}
   */
  async getMapInfoV3() {
    return await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-map-info/v3',
    });
  }

  /**
   *
   * @description Confirm email from token and code.
   * @param token : string
   * @param code : string
   * @return {Promise.<string|boolean>}
   */
  async confirmEmail(token, code) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/confirm-email',
      body: { token, code },
    });

    if (typeof result === 'object' && !result.hasOwnProperty('errorName')) {
      return result.token;
    }

    return result;
  }

  /**
   *
   * @description Send code to Email
   * @param token : string
   * @param email : string
   * @param edit : int
   * @return {Promise.<boolean>}
   */
  async sendEmailCode(token, email, edit = 0) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/profile/send-email-code',
      body: { token, email, edit },
    });

    if (
      typeof result === 'object' &&
      result.hasOwnProperty('status') &&
      result.status === 'ok'
    ) {
      return result;
    }

    return false;
  }

  /**
   *
   * @description Send SMS code
   * @param token : string
   * @param phone : string
   * @param edit : int
   * @return {Promise.<string|boolean>}
   */
  async sendSMSCode(token = this.token, phone, edit = 0) {
    const replacedPhone = String(phone)
      .replace(/[^\d+]/g, '')
      .replace(/[+]/g, '');

    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/profile/send-sms-code',
      body: { token, phone: replacedPhone, edit },
    });

    if (typeof result === 'object' && result.hasOwnProperty('status')) {
      return result;
    }

    return false;
  }

  /**
   * @description Confirm SMS code
   * @param token : string
   * @param code : string
   * @return {Promise.<Object|boolean>}
   */
  async confirmSMSCode(token, code) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/confirm-phone',
      body: { token, code },
    });

    if (
      typeof result === 'object' &&
      result.hasOwnProperty('status') &&
      result.status === 'ok'
    ) {
      return result;
    }

    return result;
  }

  /**
   *
   * @description Return html code from uri
   * @param url : string
   * @return {Promise.<string>}
   */
  loadStaticData = async (url) => {
    const response = await axios({
      method: 'GET',
      url,
    });

    return !!response ? response.data : null;
  };

  /**
   * @description Return profile data on JSON format or false when something
   *   wrong.
   * @return {Promise.<bool|object>}
   */
  async getProfile() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile',
      params: {
        token: this.token,
      },
    });
    return response.status === 'ok' ? response.result : false;
  }

  async getProfileV2() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile-v2',
      params: {
        token: this.token,
      },
    });
    return response.status === 'ok' ? response.result : false;
  }

  async getProfileV3() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile-v3',
      params: {
        token: this.token,
      },
    });
    return response.status === 'ok' ? response.result : false;
  }

  async getProfileV4() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile-v4',
      params: {
        token: this.token,
      },
    });
    return response.result;
  }

  async getProfileV5() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile-v5',
      params: {
        token: this.token,
      },
    });
    return response;
  }

  async getProfileV6() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-profile-v6',
      params: {
        token: this.token,
      },
    });
    return response;
  }

  /**
   *
   * @description Save profile data
   * @param firstName
   * @param lastName
   * @param middleName
   * @return {Promise.<string|boolean>}
   */
  async changeProfile({ firstName, lastName, middleName, email }) {
    let body = { token: this.token };
    if (firstName) body.firstname = firstName;
    if (lastName) body.lastname = lastName;
    if (middleName) body.middlename = middleName;
    if (email) body.email = email;

    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-profile',
      body,
    });

    return response;
  }

  /**
   *
   * @description Changes password
   * @param password : string
   * @return {Promise.<string|boolean>}
   */
  async changePassword({ pass, currentpass }) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-password',
      body: {
        token: this.token,
        pass,
        currentpass,
      },
    });

    return response && !response.status === 'ok' ? 'ok' : response.errorName;
  }

  /**
   * @description Check email on existing. If its true,
   * then send mail with link for restore password.
   * On link enter call restoreCheck method
   * @param email: String
   * @return { Promise.<string> }
   */
  async restore(email) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/restore-password/send-email',
      params: {
        email: email,
      },
    });

    return response && response.status === 'ok';
  }

  /**
   * @description Check email on existing. If its true,
   * then send mail with link for restore password.
   * On link enter call restoreCheck method
   * @param login: String
   * @param type: Integer
   * @return { Promise.<string> }
   */
  async easyRestore(login, type) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/restore-password-v2/change',
      body: {
        login,
        type,
      },
    });

    return response;
  }

  async restoreCheck(token) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/restore-password/check',
      params: {
        token: token,
      },
    });

    return response && response.status === 'ok';
  }

  /**
   *
   * @description Save array of cars
   * @param cars : Array
   * @return {Promise.<string|boolean>}
   */
  async changeCars(cars) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-cars',
      body: {
        token: this.token,
        car: cars,
      },
    });

    return response && response.status === 'ok';
  }

  /**
   *
   * @description Save array of cars
   * @return {Promise.<object|boolean>}
   */
  async changeCarsV2(cars) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-cars-v2',
      body: {
        token: this.token,
        car: cars,
      },
    });

    return response && response.status === 'ok';
  }

  /**
   *
   * @description Get operations
   * @return {Promise.<object|boolean>}
   */
  async getOperations() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-operations-v2',
      params: {
        token: this.token,
      },
    });

    return response && response.status === 'ok' ? response.result : false;
  }

  async getOperationsWithFilter(filter) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/profile/get-operations-with-filter',
      params: {
        token: this.token,
        StartDate: filter ? filter.periodStart : null,
        EndDate: filter ? filter.periodEnd : null,
        Operation: filter ? filter.currentOperationTypeFilter : null,
        Vehicle: filter ? filter.currentTransportFilter : null,
        Zone: filter ? filter.currentZoneFilter : null,
        OrderNumber: filter ? filter.orderNumber : null,
      },
    });

    return response;
  }

  downloadOperationsWithFilter(filter) {
    const serverUri = CityStore.serverURI;
    let params = {
      token: this.token,
      StartDate: filter ? filter.periodStart : null,
      EndDate: filter ? filter.periodEnd : null,
      Operation: filter ? filter.currentOperationTypeFilter : null,
      Vehicle: filter ? filter.currentTransportFilter : null,
      Zone: filter ? filter.currentZoneFilter : null,
      type: filter.type,
    };
    params = qs.stringify(params);
    return `${serverUri}/api/profile/download-history/v1?${params}`;
  }

  /**
   *
   * @description Get benefits of profile
   * @return {Promise.<object|boolean>}
   */
  async getBenefits() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-benefits-v2',
      params: {
        token: this.token,
      },
    });

    return response && response.status === 'ok' ? response.result : false;
  }

  /*  async getMenuOptions () {
      const response = await this.APIRequest({
        method: 'GET',
        path: '/api/options'
      });
      return response.status === 'ok' ? response.result : false;
    }*/

  async getZones() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-map-info',
    });

    if (response && response.features) {
      const zones = [];
      response.features.map((feature) => {
        if (
          feature &&
          feature.properties &&
          feature.properties.zone &&
          feature.properties.zone.zonename &&
          !zones.some(
            (zone) => zone.name === feature.properties.zone.zonenumber
          )
        ) {
          const prices = feature.properties.zone.prices.map((price) => {
            let type = null;
            let interval = null;
            const sum = price.price;

            switch (price.day) {
              case 'Суббота':
                type = 'saturday';
                break;

              case 'Воскресенье':
                type = 'sunday';
                break;

              case 'Праздник':
                type = 'holiday';
                break;

              default:
                type = 'default';
                break;
            }

            interval = {
              from: moment(price.timestart).format('HH:mm:ss'),
              to: moment(price.timeend).format('HH:mm:ss'),
            };

            return {
              type,
              interval,
              price: sum,
              category: feature.properties.category,
            };
          });

          const zone = {
            name: feature.properties.zone.zonenumber,
            prices,
            category: feature.properties.category,
          };

          zones.push(zone);
        }

        return null;
      });

      return zones.sort();
    }

    return [];
  }

  /**
   * @description Returns news
   * @param limit
   * @param offset
   * @return {Promise.<*>}
   */
  async getNews(limit = null, offset = null) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/news',
      params: {
        limit: limit,
        offset: offset,
      },
    });

    return response;
  }

  /**
   * @description Returns one new by id
   * @param id
   * @return {Promise.<*>}
   */
  async getOneNews(id) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/news/get-one',
      params: {
        id: id,
      },
    });

    return response;
  }

  /**
   * @description Returns options
   * @param id
   * @return {Promise.<*>}
   */
  async getOptions(id) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/options',
    });

    return response;
  }

  /**
   * @description Returns page by id
   * @param path
   * @return {Promise.<*>}
   */
  async getPageByPath(path) {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/pages/get-by-path',
      params: {
        path: path,
      },
    });

    return response;
  }

  async sendPaymentInfo({
    source,
    order,
    billnumber,
    amount,
    paymentDate,
    status,
  }) {
    return await this.APIRequest({
      method: 'GET',
      path: '/api/payment/payment-from-portal',
      params: {
        source,
        order,
        billnumber,
        amount,
        paymentDate,
        status,
      },
    });
  }

  /**
   * @description Provides a payment
   * @param transport
   * @param zone
   * @param payment
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  paymentStart = async ({
    transport,
    zone,
    payment,
    duration,
    parkingCode,
    phone,
  }) => {
    window.localStorage.setItem('paymentURI', window.location.href);

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start',
      body: {
        Vehicle: transport,
        Zone: zone,
        PaymentService: payment,
        Duration: duration,
        Token: this.token,
        ParkingCode: parkingCode,
        phone,
      },
    });
  };

  /**
   * @description Provides a payment
   * @param transport
   * @param zone
   * @param payment
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  unregisterPaymentStart = async ({
    transport,
    zone,
    payment,
    duration,
    parkingCode,
    phone,
  }) => {
    window.localStorage.setItem('paymentURI', window.location.href);

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start',
      body: {
        Vehicle: transport,
        Zone: zone,
        PaymentService: payment,
        Duration: duration,
        Token: Token.guestToken,
        ParkingCode: parkingCode,
        phone,
      },
    });
  };

  /**
   * @description Provides a payment
   * @param transport
   * @param zone
   * @param payment
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  postPaymentStart = async ({
    transport,
    zone,
    payment,
    duration,
    parkingCode,
    phone,
    postPaymentStartTime = null,
  }) => {
    window.localStorage.setItem('paymentURI', window.location.href);

    let date =
      postPaymentStartTime !== null
        ? String(moment(postPaymentStartTime).format())
        : String(moment().format());

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start',
      body: {
        Vehicle: transport,
        Zone: zone,
        PaymentService: payment,
        Duration: duration,
        Token: this.token,
        ParkingCode: parkingCode,
        phone,
        timestart: date,
      },
    });
  };

  /**
   * @description Provides a payment
   * @param transport
   * @param zone
   * @param payment
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  unregisterPostPaymentStart = async ({
    transport,
    zone,
    payment,
    duration,
    parkingCode,
    phone,
    postPaymentStartTime = null,
  }) => {
    window.localStorage.setItem('paymentURI', window.location.href);
    let date =
      postPaymentStartTime !== null
        ? String(moment(postPaymentStartTime).format())
        : String(moment().format());

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start',
      body: {
        Vehicle: transport,
        Zone: zone,
        PaymentService: payment,
        Duration: duration,
        Token: Token.guestToken,
        ParkingCode: parkingCode,
        phone,
        timestart: date,
      },
    });
  };

  /**
   * @description Provides a payment
   * @param transport
   * @param zone
   * @param payment
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  async sessionContinue({
    transport,
    zone,
    payment,
    duration,
    parkingCode,
    phone,
    postPaymentStartTime = null,
  }) {
    window.localStorage.setItem('paymentURI', window.location.href);
    let date =
      postPaymentStartTime !== null
        ? String(moment(postPaymentStartTime).format())
        : String(moment().format());

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start',
      body: {
        Vehicle: transport,
        Zone: zone,
        PaymentService: payment,
        Duration: duration,
        Token: this.token,
        ParkingCode: parkingCode,
        phone,
        timestart: date,
      },
    });
  }

  /**
   * @description Cost of parking session
   * @param transport
   * @param zone
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  paymentCost = async ({
    transport,
    zone,
    duration,
    parkingCode,
    timestart,
    reservation,
  }) => {
    const date = String(moment(timestart).format());
    const body = {
      Vehicle: transport,
      Zone: zone,
      Duration: duration,
      Token: this.token,
      ParkingCode: parkingCode,
    };

    if (reservation) {
      body.ReservationId = reservation;
    }

    if (timestart) {
      body.timestart = date;
    }

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/cost',
      body,
    });
  };

  /**
   * @description Cost of parking session
   * @param transport
   * @param zone
   * @param token
   * @param duration
   * @return {Promise.<void>}
   */
  unregisterPaymentCost = async ({
    transport,
    zone,
    duration,
    parkingCode,
    timestart,
  }) => {
    const date = String(moment(timestart).format());
    const body = {
      Vehicle: transport,
      Zone: zone,
      Duration: duration,
      Token: Token.guestToken,
      ParkingCode: parkingCode,
    };

    if (timestart) {
      body.timestart = date;
    }

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/cost',
      body,
    });
  };

  /**
   * @description Renews a parking
   * @param reservation
   * @param payment
   * @param duration
   * @return {Promise.<void>}
   */
  async paymentRenew({ reservation, payment, duration, phone, timestart }) {
    window.localStorage.setItem('paymentURI', window.location.href);

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/renew',
      body: {
        ReservationId: reservation,
        PaymentService: payment,
        Duration: String(duration),
        Token: this.token,
        phone,
        timestart,
      },
    });
  }

  /**
   * @description Refills user account
   * @param payment
   * @param amount
   * @return {Promise.<void>}
   * @constructor
   */
  async paymentRefill({ payment, amount, phone }) {
    window.localStorage.setItem('paymentURI', window.location.href);
    const paymentObj = {
      PaymentService: payment,
      RefillAmount: amount,
      Token: this.token,
    };

    if (phone) {
      paymentObj.phone = phone;
    }

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/refill',
      body: paymentObj,
    });
  }

  /**
   * @description Cancels a parking
   * @param reservation
   * @return {Promise.<void>}
   * @constructor
   */
  async paymentCancel({ reservation }) {
    window.localStorage.setItem('paymentURI', window.location.href);

    return await this.APIRequest({
      method: 'POST',
      path: '/api/payment/cancel',
      body: {
        ReservationId: reservation,
        Token: this.token,
      },
    });
  }

  /**
   *
   * @description Send restore password code to email.
   * @param email : string
   * @return {Promise.<string|boolean>}
   */
  async sendRestorePasswordCode(email) {
    const result = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/restore-password/send-email',
      params: {
        email: email,
      },
    });
    if (
      typeof result === 'object' &&
      result.hasOwnProperty('status') &&
      result.status === 'ok'
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @description Send token for check.
   * @param token : string
   * @return {Promise.<string|boolean>}
   */
  async sendTokenForCheckRestore(token) {
    const result = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/restore-password/check',
      params: {
        token: token,
      },
    });
    if (
      typeof result === 'object' &&
      result.hasOwnProperty('status') &&
      result.status === 'ok'
    ) {
      return true;
    }

    return false;
  }

  /**
   *
   * @description Change password
   * @param password : String
   * @param token : String
   * @return {Promise.<string|boolean>}
   */
  async restorePasswordChange(password, token) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/restore-password/change',
      body: {
        token: token,
        pass: password,
      },
    });

    return response && response.status === 'ok';
  }

  /**
   * @description Delete phone number
   * @param phone number
   * @return {Promise.<void>}
   */
  async deletePhone(phone) {
    const response = await this.APIRequest({
      method: 'DELETE',
      path: '/api/profile/delete-phone',
      body: {
        phone: phone,
        token: this.token,
      },
    });
    return response;
  }

  /**
   * @description Set main phone
   * @param phone number
   * @param token
   * @return {Promise.<void>}
   */
  async setMainPhone(phone) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/set-main-phone',
      body: {
        phone: phone,
        token: this.token,
      },
    });
    return response;
  }

  /**
   * @description Get subscriptions
   * @return {Promise.<void>}
   */
  async getSubscription() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-subscription',
    });

    return response;
  }

  /**
   * @description Pay for subscription
   * @return {Promise.<void>}
   */
  async paySubscription({
    vehicle,
    codeSubscription,
    paymentService,
    timestart,
    phone,
  }) {
    const paymentObj = {
      vehicle,
      codeSubscription,
      paymentService,
      timestart,
      token: this.token,
    };

    if (phone) {
      paymentObj.phone = phone;
    }

    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/payment/subscription',
      body: paymentObj,
    });
    return response;
  }
  /**
   * @description Get settings for current location
   * @return {Promise.<void>}
   */
  async getSettings() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-options-info',
    });

    return response;
  }

  /**
   * @description Get settings for current location
   * @return {Promise.<void>}
   */
  getSettingsV2 = async () => {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-options-info-v2',
    });

    return response;
  };

  /**
   * @description Get settings for current location
   * @return {Promise.<void>}
   */
  getSettingsV3 = async () => {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/info/get-options-info-v3',
    });

    return response;
  };

  async addCar(car) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/profile/car',
      body: {
        token: this.token,
        ...car,
      },
    });

    return response;
  }

  async changeCar(newCar, oldCar) {
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/car',
      body: {
        token: this.token,
        car: {
          ...newCar,
          newgrz: newCar['grz'],
          oldgrz: oldCar['grz'],
        },
      },
    });

    return response;
  }

  async removeCar(car) {
    const response = await this.APIRequest({
      method: 'DELETE',
      path: '/api/profile/car',
      body: {
        token: this.token,
        ...car,
      },
    });

    return response;
  }

  async startNoBalanceSession(paymenOptions) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/payment/start/v2',
      body: {
        token: this.token,
        ...paymenOptions,
      },
    });

    return response;
  }
  /**
   *
   * @param {
   *  OrderId: string
   *  PaymentService: string
   * } paymenOptions
   */
  async cancelNoBalanceSession(paymenOptions) {
    let body = { token: this.token, OrderId: paymenOptions.orderId };

    if (paymenOptions.paymentMethod) {
      body.PaymentService = paymenOptions.paymentMethod;
    }

    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/payment/cancel/v2',
      body,
    });

    return response;
  }

  /**
   *
   * @param {
   *  OrderId: string
   *  PaymentService: string
   * } paymenOptions
   */
  async cancelDefferedSession(paymenOptions) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/payment/deffered/v1',
      body: {
        token: this.token,
        PaymentService: paymenOptions.paymentMethod,
        OrderId: paymenOptions.orderId,
      },
    });

    return response;
  }

  /**
   *
   * @param {
   *  OrderId: string
   *  PaymentService: string
   * } paymenOptions
   */
  async abortSession(paymenOptions) {
    const response = await this.APIRequest({
      method: 'POST',
      path: '/api/payment/break/v1',
      body: {
        token: this.token,
        PaymentService: paymenOptions.paymentMethod,
        OrderId: paymenOptions.orderId,
      },
    });

    return response;
  }

  async loadPenalty() {
    const response = await this.APIRequest({
      method: 'get',
      path: '/api/finesstorage/fines/v1',
      params: {
        token: this.token,
      },
    });

    return response;
  }

  async loadPenaltyPicture(uin) {
    const response = await this.APIRequest({
      method: 'get',
      path: '/api/finesstorage/fineimage/v1',
      params: {
        token: this.token,
        uin: uin,
      },
    });
    return response;
  }

  async getPlanarPrice(cardNumber) {
    const response = await this.APIRequest({
      method: 'get',
      path: '/api/shtrihGate/sessions/cost',
      params: {
        token: this.token,
        cardNumber,
      },
    });
    return response;
  }

  async payPlanar({ paymentService, sessionId, cardNumber }) {
    const response = await this.APIRequest({
      method: 'post',
      path: '/api/shtrihGate/sessions/pay',
      body: {
        token: this.token,
        cardNumber,
        paymentService,
        sessionId,
      },
    });
    return response;
  }

  async getSmsCommands() {
    const response = await this.APIRequest({
      method: 'get',
      path: '/api/sms-commands',
    });

    return response;
  }

  async getNotification() {
    const response = await this.APIRequest({
      method: 'get',
      path: '/api/notification/popup',
    });

    return response;
  }

  async getAccountSettings() {
    const response = await this.APIRequest({
      method: 'GET',
      path: '/api/profile/get-settings-v1',
      params: {
        token: this.token,
      },
    });
    return response.status === 'ok' ? response.result : false;
  }

  async changeAccountSettings({value, name}) {
    const normalize = {
      pushOff: '',
      checkParkingSessionBefore: '',
      checkParkingSession: '',
      reportHistoryOperation: 'ПолучатьОтчетОбОперациях'
    }
    const body = {
      token: this.token,
      [normalize[name]]: value
    };
    const params = {
      token: this.token
    }
    const response = await this.APIRequest({
      method: 'PUT',
      path: '/api/profile/change-settings-v1',
      body,
      params
    });

    return response;
  }

  /**
   * @description returns confirmation type (sms/call)
   * @return {Promise.<Object>}
   */
  async getConfirmationType() {
    const result = await this.APIRequest({
      method: 'GET',
      path: '/api/auth/get-confirmation-type-v2',
    });

    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async checkCapthca(captchaToken) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/auth/check-captcha',
      body: {
        'g-recaptcha-response': captchaToken
      }
    });

    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async getReceiptsList() {
    const result = await this.APIRequest({
      method: 'get',
      path: '/api/receipt-list',
      params: {
        token: this.token,
      },
    });

    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async getReceipt(
    number
  ) {
    const result = await this.APIRequest({
      method: 'GET',
      path: '/api/receipt',
      params: {
        'token': this.token,
        'receiptnumber': number,
      },
      responseType: 'blob',
    });

    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async sendReceiptByEmail(
    { number, email }
  ) {
    const result = await this.APIRequest({
      method: 'GET',
      path: '/api/sendreceipt',
      params: {
        'token': this.token,
        'receiptnumber': number,
        'email': email,
      },
    });

    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async createReceiptLegal(
    {name, ogrn, inn, kpp, address, type, sum}
  ) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/creatrefill',
      body: {
        'sum': sum,
        'payer': {
          'name': name,
          'ogrn': ogrn,
          'inn': inn,
          'kpp': kpp,
          'address': address,
          'type': type,
        },
      },
      params: {
        'token': this.token,
      },
    });
    return result ? result : null;
  }

  /**
   * @return {Promise.<Object>}
   */
  async createReceiptIndividual(
    {firstName, lastName, middleName, typeDoc, seriesDoc, numberDoc, DateDoc, issuer, address, type, sum}
  ) {
    const result = await this.APIRequest({
      method: 'POST',
      path: '/api/creatrefill',
      body: {
        'sum': sum,
        'payer': {
          'firstName': firstName,
          'lastName': lastName,
          'middleName': middleName,
          'typeDoc': typeDoc,
          'seriesDoc': seriesDoc,
          'numberDoc': numberDoc,
          'DateDoc': DateDoc,
          'issuer': issuer,
          'address': address,
          'type': type,
        },
      },
      params: {
        'token': this.token,
      },
    });
    return result ? result : null;
  }

}

export default new APIProvider();
