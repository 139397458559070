import React from 'react';
import Button from "reactstrap/lib/Button";
import AutoSubmit from "../../../../Common/Inputs/AutoSubmit";
import isEmpty from "lodash/isEmpty";
import FormControl from "../../common/FormControl";
import FormGroup from "reactstrap/lib/FormGroup";
import { translate } from "react-i18next";
import { observer } from "mobx-react";
import { FormikInput } from "../../../../Common/Inputs";
import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import SpinnerButton from "../../../../Common/Buttons/SpinnerButton";
import APIProvider from "../../../../../Stores/APIProvider";
import GetReceipt from "./GetReceipt";
import ReceiptLegal from "./ReceiptLegal";
import ReceiptIndividual from "./ReceiptIndividual";
import { Col, Row } from "reactstrap";

@translate(['parking'], { wait: true })
@observer
class ReceiptForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isIndividual: true,
    }
  }

  onAbort = () => {
    const { onAbort } = this.props;
    onAbort();
  }


  render() {
    if (!this.props.isGetReceipt) {
      return (
        <>
          <form onSubmit={this.props.onSubmit} >
            {this.state.isIndividual ? (
              <>
                <FormGroup >
                  <Row>
                    <Col>
                      <span>{this.props.t('operations:payer')}</span>
                      <Button
                        outline
                        active color="primary"
                        className="mx-1"
                        onClick={() => this.setState({isIndividual: true}) }
                      >
                        {this.props.t('operations:individual')}
                      </Button>
                      <Button
                        outline
                        color="secondary"
                        className="mx-1"
                        onClick={() => this.setState({isIndividual: false}) }
                      >
                        {this.props.t('operations:legalEntity')}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <ReceiptIndividual
                  amount={this.props.amount}
                  onAbort={this.onAbort}
                  t={this.props.t}
                />
              </>
            ) : (
              <>
                <FormGroup>
                  <Row >
                    <Col>
                      <span>{this.props.t('operations:payer')}</span>
                      <Button
                        outline
                        color="secondary"
                        className="mx-1"
                        onClick={() => this.setState({isIndividual: true}) }
                      >
                        {this.props.t('operations:individual')}
                      </Button>
                      <Button
                        outline
                        active
                        color="primary"
                        className="mx-1"
                        onClick={() => this.setState({isIndividual: false}) }
                      >
                        {this.props.t('operations:legalEntity')}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <ReceiptLegal
                  amount={this.props.amount}
                  onAbort={this.onAbort}
                  t={this.props.t}
                />
              </>
            )}
          </form>
        </>
      );
    } else {
      return (
        <GetReceipt
          onAbort={this.onAbort}
          receiptnumber={this.props.receiptnumber}
          receiptType={this.props.receiptType}
          t={this.props.t}
        />
      );
    }
  };
}

export default ReceiptForm;